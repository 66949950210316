// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  clientId: 'Afsfd8wLuhxe7Ov4XP_LwT51kGeFxeDYzufCjBfh715m5p-HttOvxYC4sVI9UWVGpA69zcvH8Dk0IWvl',

  //DEVELOPMENT
  //serverURL: 'http://localhost:4243',
  //API_URL: 'http://localhost:3000',
  //tpv_url: "https://sis-t.redsys.es:25443/sis/realizarPago",
  //tpv_pisp_url: 'https://w5.grupobbva.com/ETPP/etpp_mult_web_frontcertification_02/commerce/certificate',

  //PRODUCTION
  serverURL: 'http://localhost:4243',
  API_URL: 'https://ws-prod.aitanasolutions.com',
  tpv_url : "https://sis.redsys.es/sis/realizarPago",
  tpv_pisp_url: 'https://w3.grupobbva.com/ETPP/etpp_mult_web_frontpisp_01/router/altaoperacion',

  completed_url: "https://flex.exxita.com/completed",
  //IMG_URL: 'https://wsaitanaangular.aitanasolutions.com',
  almacen: 398,
  fk_entity: 82,
  stripe_pk:'pk_live_51Jtr0FCThXVSyHdsvp5udNG9rt2aoZV1hisXg47hftXpyQMT7qNZHzOipl2FNxqZWb8BgAJdcsUGFwKJw1uatsKm002dbLUTXB',

  clientSecret: 'MzUzYjMwMmM0NDU3NGY1NjUwNDU2ODdlNTM0ZTdkNmE6Mjg2OTI0Njk3ZTYxNWE2NzJhNjQ2YTQ5MzU0NTY0NmM='
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

